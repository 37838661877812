import axios from "axios";
import moment from "moment";
import { v4 as uuid } from 'uuid';
import authConfig, { IAuthConfig } from "./authConfig";
import {
    IAccountInfo, IAccountEnvelope, ILoginRequest, ILogoutRequest, IRefreshRequest,
    ISwitchTenantRequest, ISwitchFacilityRequest, IDeviceInfo, IRegisterDeviceRequest,
    IStaffLoginRequest, IRecoverRegistrationRequest
} from "./IAccountInfo";
import { IResetPasswordRequest, IResetPasswordEnvelope, IConfirmPasswordRequest, IConfirmPasswordEnvelope }  from "./ResetPassword";
import { IAuthProvider, IPolicyRoles } from "./IAuthProvider";
import log, { setLogToken } from "../logging";
import i18n, {getLanguageHeader} from "../../locales/i18n";
import tracing from '../tracingContext';
import { imageCache } from "../dataCache";
import { save as saveAction } from "../cache/actions"

export const roles: IPolicyRoles = {
    SiteAdministrator: "CHS Administrator",
    SiteAssistant: "Site Assistant",
    MultiFacilityAdministrator: "Organization Administrator",
    FacilityAdministrator: "Staff Manager",
    FacilityAssistant: "Facility Assistant",
    LeadHousekeeper: "Lead Housekeeper",
    Nursing: "Nursing",
    RegisteredDevice: "Registered Device",
    Housekeeper: "Staff",
    TabletRegistration: "Tablet Registration"
}

class AuthProvider implements IAuthProvider {
    readonly accountInfoKey: string = "AuthAccountInfo";
    readonly deviceInfoKey: string = "AuthDeviceInfo";
    readonly hardwareInfoKey: string = "Identifier";
    readonly Roles: IPolicyRoles = roles;

    public getAssociatedRoles = (role: string): string[] => {
            if(this.Roles.SiteAdministrator === role){
                return [
                    this.Roles.SiteAdministrator, 
                    this.Roles.SiteAssistant, 
                    this.Roles.MultiFacilityAdministrator, 
                    this.Roles.FacilityAdministrator, 
                    this.Roles.FacilityAssistant, 
                    this.Roles.LeadHousekeeper, 
                    this.Roles.Nursing, 
                    this.Roles.RegisteredDevice, 
                    this.Roles.Housekeeper,
                    this.Roles.TabletRegistration];
            }

            if(this.Roles.SiteAssistant === role){
                return [
                    this.Roles.SiteAssistant, 
                    this.Roles.MultiFacilityAdministrator, 
                    this.Roles.FacilityAdministrator, 
                    this.Roles.FacilityAssistant, 
                    this.Roles.LeadHousekeeper, 
                    this.Roles.Nursing, 
                    this.Roles.RegisteredDevice, 
                    this.Roles.Housekeeper,
                    this.Roles.TabletRegistration];
            }

            if(this.Roles.MultiFacilityAdministrator === role){
                return [                 
                    this.Roles.MultiFacilityAdministrator, 
                    this.Roles.FacilityAdministrator, 
                    this.Roles.FacilityAssistant, 
                    this.Roles.LeadHousekeeper, 
                    this.Roles.Nursing, 
                    this.Roles.RegisteredDevice, 
                    this.Roles.Housekeeper,
                    this.Roles.TabletRegistration];
            }
            
            if(this.Roles.FacilityAdministrator === role){
                return [
                    this.Roles.FacilityAdministrator, 
                    this.Roles.FacilityAssistant, 
                    this.Roles.LeadHousekeeper, 
                    this.Roles.Nursing, 
                    this.Roles.RegisteredDevice, 
                    this.Roles.Housekeeper];
            }

            if(this.Roles.FacilityAssistant === role){
                return [
                    this.Roles.FacilityAdministrator, 
                    this.Roles.FacilityAssistant, 
                    this.Roles.LeadHousekeeper, 
                    this.Roles.Nursing, 
                    this.Roles.RegisteredDevice, 
                    this.Roles.Housekeeper];
            }

            if(this.Roles.FacilityAssistant === role){
                return [                    
                    this.Roles.FacilityAssistant, 
                    this.Roles.LeadHousekeeper, 
                    this.Roles.Nursing, 
                    this.Roles.RegisteredDevice, 
                    this.Roles.Housekeeper];
            }

            if(this.Roles.LeadHousekeeper === role){
                return [                    
                    this.Roles.LeadHousekeeper, 
                    this.Roles.Nursing, 
                    this.Roles.RegisteredDevice, 
                    this.Roles.Housekeeper];
            }

            if(this.Roles.Nursing === role){
                return [                    
                    this.Roles.Nursing, 
                    this.Roles.RegisteredDevice, 
                    this.Roles.Housekeeper];
            }
            
            if(this.Roles.TabletRegistration === role){
                return [
                    this.Roles.TabletRegistration,
                    this.Roles.RegisteredDevice, 
                    this.Roles.Housekeeper];
            }

        return [role];
    }

    public hasRole = (role: string): boolean => {
        const account = this.getAccountInfo();
        return !account || this.getAssociatedRoles(account.role).some(r => r === role);
    }

    private accountInfo: IAccountInfo | null;
    private deviceInfo: IDeviceInfo | null;

    constructor(private authConfig: IAuthConfig, public apiUrl: string) {
        this.loadAccountInfo();
    }

    public isAuthenticated = () => {
        const accountInfo = this.getAccountInfo();
        return accountInfo != null && this.getTokenLeftTimeMin(accountInfo) > 0;
    }

    public isRegisteredDevice = () => {
        const deviceInfo = this.getDeviceInfo();
        return deviceInfo != null;
    }

    public recoverDeviceRegistration(hardwareId: string): Promise<any> {

        const request: IRecoverRegistrationRequest = {
            data: {
                hardwareId: hardwareId
            }
        };

        return new Promise<any>((resolve, reject) => {
            axios.post(`${this.apiUrl}/${this.authConfig.recoverDeviceRegistrationEndpoint}`, request, {
                headers: {
                    ...getLanguageHeader()
                }
            })
                .then(r => {
                if (r.status === 200 && !!r.data) {
                    const deviceInfo: IDeviceInfo = r.data;
                    this.setDeviceInfo(deviceInfo)
                    resolve(deviceInfo)
                }
                else reject(new Error("Device not registgered"))
            })
            .catch(error => {
                reject(error)
            });
        })
    }

    public getAccountInfo(): IAccountInfo | null {
        return this.accountInfo || this.loadAccountInfo();
    }

    public getDeviceInfo(): IDeviceInfo | null {
        return this.deviceInfo || this.loadDeviceInfo();
    }

    public switchTenant(tenantId: string): Promise<any> {
        const request: ISwitchTenantRequest = {
            data: { tenantId }
        }
        return axios.post(`${this.apiUrl}/${this.authConfig.switchTenantEndpoint}`, request, {
            headers: {
                Authorization: 'Bearer ' + this.getAccountInfo()?.token.token
            }
        }).then(r => {
                if (r.status === 200) {
                    const accountInfo = r.data.accountInfo as IAccountInfo;
                    this.setAccountInfo(accountInfo);
                    return import('../cache').then((appCache) => {
                        import('../../store/store').then((store) => {
                            appCache.default.clearCache().then(() => {
                                store.default.dispatch(saveAction({}))
                                return appCache.default.loadInitialStateFromAPI().then(() => {
                                    tracing.startNewSession(accountInfo.email);
                                    log.info(`[${accountInfo.email}] has switched tenants to [${accountInfo.tenantId}]`);
                                })
                            })
                        })
                    })
                }
                else throw new Error(`Couldn't switch tenants [${this.accountInfo?.email}] to [${tenantId}]: ${r}`);
            })
            .catch(error => {
                log.error('Switch tenant error', error);
                throw error;
            });
    }

    public switchFacility(facilityId: string): Promise<any> {
        const request: ISwitchFacilityRequest = {
            data: { facilityId }
        }
        return axios.post(`${this.apiUrl}/${this.authConfig.switchFacilityEndpoint}`, request, {
            headers: {
                Authorization: 'Bearer ' + this.getAccountInfo()?.token.token
            }
        }).then(r => {
            if (r.status === 200) {
                const accountInfo = r.data.accountInfo as IAccountInfo;
                this.setAccountInfo(accountInfo);
                return import('../cache').then((appCache) => {
                    import('../../store/store').then((store) => {
                        appCache.default.clearCache().then(() => {
                            store.default.dispatch(saveAction({}))
                            return appCache.default.loadInitialStateFromAPI().then(() => {
                                tracing.startNewSession(accountInfo.email);
                                log.info(`[${accountInfo.email}] has switched facilities to [${accountInfo.facility?.name}]`);
                            })
                        })
                    })
                })



            }
            else throw new Error(`Couldn't switch facility [${this.accountInfo?.email}] to [${facilityId}]: ${r}`);
        })
            .catch(error => {
                log.error('Switch Site error', error);
                throw error;
            });
    }

    public login(email: string, password: string): Promise<any> {

        const request: ILoginRequest = {
            user: { email, password }
        };

        return new Promise<any>((resolve, reject) => {
            axios.post(`${this.apiUrl}/${this.authConfig.loginEndpoint}`, request, {
                headers: {
                    ...getLanguageHeader()
                }
            })
            .then(r => {
                if (r.status === 200) {
                    const accountInfo: IAccountInfo = r.data.accountInfo;
                    this.setAccountInfo(accountInfo);
                    tracing.startNewSession(accountInfo.email);
                    setLogToken(accountInfo.token.token)
                    log.info(`${accountInfo.email} has logged in`);

                    import('../cache').then((appCache) => {
                        appCache.default.loadInitialStateFromAPI().then(() => {
                            resolve("Success")
                        })
                            .catch(error => reject(error));
                    })
                }
                else {                    
                    reject(new Error(`Couldn't login by '${email}': ${r}`));
                };
            })
            .then(r => imageCache.clear())
            .catch(error => {
                log.error('Authentication error', error);
                reject(error);
            });
        })
    }

    public logout(): Promise<any> {
        const request: ILogoutRequest = {
            logout: {}
        }
        const loginUrl = ""
        return new Promise((resolve, reject) => {
            import('../cache').then((appCache) => {
                appCache.default.clearCache()
                resolve("Success");
            }).catch((error) => {
                reject(error);
            })
        }).then((r) => {            
            const accountInfo = this.getAccountInfo()
            this.setAccountInfo(null)
            if(!!accountInfo) log.info(`${accountInfo.email} has logged out`);
            if (accountInfo != null && this.getTokenLeftTimeMin(accountInfo) > 0) {
                return axios.post(`${this.apiUrl}/${this.authConfig.logoutEndpoint}`, request, {
                    headers: {
                        Authorization: 'Bearer ' + accountInfo.token.token
                    }
                })
                .then(r => {
                    setLogToken(null)
                    window.location.href = loginUrl
                })
                .catch(error => {
                    log.error(`Logout error`, error);
                    setLogToken(null)
                    window.location.href = loginUrl
                });
            }
            else {
                setLogToken(null)
                window.location.href = loginUrl
            }
            return r;
        })
        .catch((error) => {
            log.error(`Logout error`, error);
            setLogToken(null)
        });
    }

    public staffLogin(pincode: string): Promise<any> {

        const deviceInfo = this.getDeviceInfo();
        if (!deviceInfo) return Promise.reject("Device not registered")
        const request: IStaffLoginRequest = {
            pincode: pincode
        };

        return new Promise<any>((resolve, reject) => {
            axios.post(`${this.apiUrl}/${this.authConfig.staffLoginEndpoint}`, request, {
                headers: {
                    ...getLanguageHeader(),
                    Authorization: 'Bearer ' + deviceInfo?.token?.token
                }
            }).then(r => {
                if (r.status === 200) {                    
                    const accountInfo: IAccountInfo = r.data.accountInfo;
                    this.setAccountInfo(accountInfo);
                    tracing.startNewSession(accountInfo.email);
                    setLogToken(accountInfo.token.token)
                    log.info(`${accountInfo.email} has logged in via pincode`);
                    resolve(r)
                }
                else {
                    reject(new Error(`Couldn't login': ${r}`));
                };
            })
            .catch(error => {
                log.error('Authentication error', error);
                reject(error);
            });
        }).then((r) => {
            return import('../cache').then((appCache) => {
                appCache.default.loadInitialStateFromAPI();
            })
        })
    }

    public registerDevice(): Promise<any> {
        const request: IRegisterDeviceRequest = {
            data: {
                deviceId: uuid(),
                hardwareId: this.getHardwareId()
            }
        };
        return new Promise<any>((resolve, reject) => {
            const accountInfo = this.getAccountInfo()
            if (accountInfo) {
                axios.post(`${this.apiUrl}/${this.authConfig.registerDeviceEndpoint}`, request, {
                    headers: { Authorization: 'Bearer ' + accountInfo?.token?.token }
                }).then(r => {
                    if (r.status === 200) {
                        const deviceInfo = r.data;
                        this.setDeviceInfo(deviceInfo);
                        log.info(`${accountInfo.email} has registered a new device`);
                        resolve("Success")
                    }
                    else {
                        reject(new Error(`Couldn't register device': ${r}`));
                    };
                })
                .catch(error => {
                    log.error('Device registration error', error);
                    reject(error);
                });
            }
        }).then((r) => {
            this.logout()
        })
    }

    public unregisterDevice() {
        const deviceInfo = this.getDeviceInfo();
        this.setDeviceInfo(null);
        if (deviceInfo !== null) {
            const request: IRegisterDeviceRequest = {
                data: {
                    deviceId: deviceInfo.deviceId
                }
            };
            return new Promise<any>((resolve, reject) => {
                const accountInfo = this.getAccountInfo()
                if (accountInfo) {
                    axios.post(`${this.apiUrl}/${this.authConfig.unregisterDeviceEndpoint}`, request, {
                        headers: { Authorization: 'Bearer ' + accountInfo?.token?.token }
                    }).then(r => {
                        resolve(r)
                    }).catch(error => {
                        log.error('Device registration error', error);
                        reject(error);
                    });
                }
            }).then((r) => {
                this.logout()
            })
        }
    }

    public sendResetPassword(email: string): Promise<boolean> {

        const request: IResetPasswordRequest = {
            resetPassword : {
                email,
                language: i18n.language,
                resetUrl: this.getResetUrl()
            }
        };

        return axios.post(`${this.apiUrl}/${this.authConfig.resetPasswordEndpoint}`, request, {
                headers: {                     
                    ...getLanguageHeader()
                }
            })
            .then(r => {
                if (r.status === 200) {
                    const resetInfo: IResetPasswordEnvelope = r.data;
                    return resetInfo.resetPasswordInfo.requestSent;
                }
                else
                    throw new Error(`Couldn't send reset password for '${email}': ${r}`);
            })
            .catch(error => {
                log.error('Send reset password request error', error);
                throw error;
            });
    }

    public getResetUrl() : string {
        return `${window.location.origin}/confirm-password/{0}`;
    }

    public confirmResetPassword(email: string, password: string, hash: string): Promise<boolean> {

        const request: IConfirmPasswordRequest = {
            confirmPassword: {
                email,
                password: password,
                hash: hash
            }
        };

        return axios.post(`${this.apiUrl}/${this.authConfig.confirmPasswordEndpoint}`, request, {
                headers: {                     
                    ...getLanguageHeader()
                }
            })
            .then(r => {
                if (r.status === 200) {
                    const resetInfo: IConfirmPasswordEnvelope = r.data;
                    return resetInfo.confirmPasswordInfo.passwordReset;
                }
                else
                    throw new Error(`Couldn't reset password for '${email}': ${r}`);
            })
            .catch(error => {
                log.error('Reset password error', error);
                throw error;
            });
    }

    public getAccessToken(): Promise<string | null> {
        const accountInfo = this.getAccountInfo();

        if (accountInfo != null) {
            const diffInMinutes = this.getTokenLeftTimeMin(accountInfo);

            if (diffInMinutes <= 0) {
                // expired
                this.setAccountInfo(null);
            } else if (diffInMinutes < this.authConfig.refreshThresholdMin) {
                // need refresh
                const request: IRefreshRequest = { refresh: {} };
                return axios.post(`${this.apiUrl}/${this.authConfig.refreshEndpoint}`, request,
                        {
                            headers: { Authorization: 'Bearer ' + accountInfo.token.token }
                        })
                    .then(r => {
                        if (r.status === 200) {
                            const accountResponse: IAccountEnvelope = r.data;
                            this.setAccountInfo(accountResponse.accountInfo);
                            return accountResponse.accountInfo.token.token;
                        }
                        else
                            throw new Error(`Couldn't refresh login for '${accountInfo.email}': ${r}`);
                    })
                    .catch(error => {
                        log.error('Authentication error', error);
                        return null;
                    });
            }
        }

        return new Promise<string | null>(resolve => {
            resolve(accountInfo != null ? accountInfo.token.token : null);
        });
    }

    private getTokenLeftTimeMin(accountInfo: IAccountInfo): number {
        const expiration = moment(accountInfo.token.expiration);
        const duration = moment.duration(expiration.diff(moment.utc()));
        const diffInMinutes = duration.asMinutes();
        return diffInMinutes;
    }

    private loadAccountInfo(): IAccountInfo | null {
        const strAccount = localStorage.getItem(this.accountInfoKey);
        try {
            const account = strAccount !== null
                ?
                JSON.parse(strAccount) as IAccountInfo
                : null;
            
            return account;
        } catch (e) {
            log.error("Couldn't parse AccountInfo state", e);
            localStorage.removeItem(this.accountInfoKey);
            return null;
        }
    }

    private loadDeviceInfo(): IDeviceInfo | null {
        const strAccount = localStorage.getItem(this.deviceInfoKey);
        try {
            const account = strAccount !== null ? JSON.parse(strAccount) as IDeviceInfo : null;
            return account;
        } catch (e) {
            log.error("Couldn't parse DeviceInfo state", e);
            localStorage.removeItem(this.deviceInfoKey);
            return null;
        }
    }

    private setAccountInfo(accountInfo: IAccountInfo | null): void {
        this.accountInfo = accountInfo;
        if(accountInfo)
            localStorage.setItem(this.accountInfoKey, JSON.stringify(accountInfo));
        else
            localStorage.removeItem(this.accountInfoKey);
    }

    private setDeviceInfo(deviceInfo: IDeviceInfo | null): void {
        this.deviceInfo = deviceInfo;
        if (deviceInfo)
            localStorage.setItem(this.deviceInfoKey, JSON.stringify(deviceInfo));
        else
            localStorage.removeItem(this.deviceInfoKey);
    }

    public setHardwareId(hardwareId?: string): void {
        if (hardwareId)
            localStorage.setItem(this.hardwareInfoKey, hardwareId);
        else
            localStorage.removeItem(this.hardwareInfoKey);
    }

    public getHardwareId(): string | undefined {
        var result = localStorage.getItem(this.hardwareInfoKey);
        return result == null ? undefined : result;
    }
}

export const PolicyRoles = roles

export const authProvider = new AuthProvider(authConfig, process.env.REACT_APP_API_URI);
