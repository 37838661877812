import * as React from 'react';
import classnames from "classnames";

interface IconProps {
    icon: string
    className?: string;
    [key: string]: any
}

export const Icon = (props: IconProps) => {
    if (!props.icon) return null
    else if (props.icon.startsWith('sprite-')) {
        return (<svg {...props}><use xlinkHref={`/assets/sprite-defs-20241009.svg#${props.icon}`} /></svg>)
    } else {
        return (<i {...props} className={classnames(props.icon, props.className)}></i>)
    }
};

export default Icon;
